/*==========================
  VARIABLES
==========================*/
:root {
  --color-background-root: #F7FBFE;
  --color-background-top-1: #6ca7c4;
  --color-background-top-2: #afcede;
  --color-text-bottom: #6B7280;
}

@media (prefers-color-scheme: dark) {
	:root {
    --color-background-top-1: #111726;
    --color-background-top-2: #1A223A;
    --color-text-bottom: #6B7280;
	}
}

/*==========================
  CSS RESET
 ==========================*/
*,
:after,
:before {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow-x: hidden;
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 100%;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}


/*==========================
  TOP
 ==========================*/
.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: var(--color-background-root);
}

.stars {
  background: url(./images/stars.png);
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.top {
  display: flex;
  flex: 2;
  flex-direction: column;
  width: 100%;
  background-image: linear-gradient(var(--color-background-top-1), var(--color-background-top-2));
  position: relative;
  justify-content: center;
  align-items: center;
}

.wave {
  position: absolute;
  bottom: -10px;
  background-image: url(./images/wave.svg);
  background-position: center bottom;
  background-repeat: repeat-x;
  height: 200px;
  width: 100%;
  pointer-events: none;
}

.logo {
  display: flex;
  max-width: 140px;
  width: auto;
  position: absolute;
  top: -10px;
}

@media (max-width: 414px) {
  .logo {
    top: -12px;
    max-width: 110px;
  }
}

.top h1 {
  text-align: center;
  color: white;
  font-weight: 300;
  font-size: 2.2rem;
  opacity: 0.9;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1)
}

@media (max-width: 840px) {
  .top h1 {
    font-size: 2rem;
  }
}

@media (max-width: 414px) {
  .top h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 374px) {
  .top h1 {
    font-size: 1.4rem;
  }
}

.top h2 {
  max-width: 800px;
  opacity: 0.9;
  color: white;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 200;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1)
}

@media (max-width: 840px) {
  .top h2 {
    max-width: 90%;
    font-size: 1.6rem;
  }
}
  
@media (max-width: 414px) {
  .top h2 {
    max-width: 90%;
    font-size: 1.3rem;
  }
}

@media (max-width: 374px) {
  .top h2 {
    font-size: 1.1rem;
  }
}

.badges {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}

.badges img {
  max-height: 50px;
  margin: 16px;
  border-radius: 5px;
  transition: box-shadow 200ms;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}


@media (max-width: 414px) {
  .badges img {
    max-height: 40px;
  }
}

.badges img:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.badges img:active {
  transform: scale(0.98);
}

.bottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom h3 {
  text-align: center;
  line-height: 42px;
  font-weight: 300;    
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--color-text-bottom);
}

@media (max-width: 414px) {
  .bottom h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 374px) {
  .bottom h3 {
    font-size: 1.1rem;
  }
}

/*==========================
  GITHUB CORNER
 ==========================*/
 .github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

.github-corner svg {
  color: var(--color-background-top-1)
}